import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";

// Constants
const BLANK_IMG = toAbsoluteUrl("/media/svg/avatars/blank.svg");
const ALLOWED_FILE_TYPES = ".png, .jpg, .jpeg";

// Types
interface FormData {
  name: string;
  fee: string;
  icon: File | null;
  iconURL: string;
}

interface AddSubCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddSubCategory: (formData: any) => void;
}

const AddSubCategoryModal: React.FC<AddSubCategoryModalProps> = ({
  isOpen,
  onClose,
  handleAddSubCategory,
}) => {
  // State
  const [formData, setFormData] = useState<FormData>({
    name: "",
    fee: "",
    icon: null,
    iconURL: "",
  });

  // Effects
  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (isOpen) {
      bodyClassList.add("modal-open");
    } else {
      bodyClassList.remove("modal-open");
    }

    return () => bodyClassList.remove("modal-open");
  }, [isOpen]);

  // Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (files: FileList | null) => {
    if (!files?.length) return;

    const file = files[0];
    const fileURL = URL.createObjectURL(file);

    setFormData((prev) => ({
      ...prev,
      icon: file,
      iconURL: fileURL,
    }));
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files);
  };

  const handleClose = () => {
    setFormData({
      name: "",
      fee: "",
      icon: null,
      iconURL: "",
    });
    onClose();
  };

  const handleSave = async () => {
    await handleAddSubCategory(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div
        className="modal fade show d-block"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h2 className="fw-bolder">Tambah Sub Kategori</h2>
              <button
                type="button"
                className="btn btn-icon btn-sm btn-active-icon-primary"
                onClick={handleClose}
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </button>
            </div>

            {/* Body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form className="form" noValidate>
                {/* Icon Upload */}
                <div
                  className="fv-row mb-7"
                  id="drop-zone"
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <label className="d-block fw-bold fs-6 mb-2 required">
                    Icon
                  </label>
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage: `url('${formData.icon || BLANK_IMG}')`,
                    }}
                  >
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{
                        backgroundImage: `url('${
                          formData.iconURL || BLANK_IMG
                        }')`,
                        borderRadius: "100%",
                      }}
                    />
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      <input
                        type="file"
                        name="icon"
                        accept={ALLOWED_FILE_TYPES}
                        onChange={(e) => handleFileUpload(e.target.files)}
                      />
                      <input type="hidden" name="avatar_remove" />
                    </label>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                  </div>
                  <div className="form-text">
                    Tipe file yang diizinkan: png, jpg, jpeg.
                  </div>
                </div>

                {/* Name Input */}
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Nama Sub Kategori
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Masukkan nama sub kategori"
                    autoComplete="off"
                  />
                </div>

                {/* Fee Input */}
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Fee Seller
                  </label>
                  <input
                    type="number"
                    name="fee"
                    value={formData.fee}
                    onChange={handleInputChange}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Masukkan persentase"
                    autoComplete="off"
                  />
                </div>
              </form>
            </div>

            {/* Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export { AddSubCategoryModal };
