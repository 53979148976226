import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../../../_metronic/partials";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";

type DashboardWidget = {
  buyer: number;
  seller: number;
  expedition: number;
};

type PieChartsProps = {
  data: DashboardWidget;
};

const PieCharts: React.FC<PieChartsProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  useEffect(() => {
    if (
      chartRef.current &&
      data.buyer !== 0 &&
      data.seller !== 0 &&
      data.expedition !== 0
    ) {
      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(chartRef.current.clientHeight, data)
      );
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartRef, data, mode]);

  const calculatePercentage = (value: number) => {
    const total = data.buyer + data.seller + data.expedition;
    return total === 0 ? 0 : ((value / total) * 100).toFixed(2);
  };

  return (
    <div className="col-xl-6">
      <div className="card card-flush h-xl-100">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="fw-bold text-gray-900 fs-2x">Pengguna</span>
          </h3>
        </div>
        <div className="card-body pb-3">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="kt_chart_widgets_22_tab_content_1"
            >
              <div className="d-flex flex-wrap flex-md-nowrap">
                <div className="me-md-5 w-100">
                  <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                    <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                      <div className="symbol symbol-50px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-delivery-2 fs-2qx text-primary">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                            <span className="path7" />
                            <span className="path8" />
                          </i>
                        </span>
                      </div>
                      <div className="me-2">
                        <a
                          href="#"
                          className="text-gray-800 text-hover-primary fs-6 fw-bold"
                        >
                          Ekspedisi
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-900 fw-bolder fs-2x me-3">
                        {data.expedition}
                      </span>
                      <span className="badge badge-lg badge-light-primary align-self-center px-2">
                        {calculatePercentage(data.expedition)}%
                      </span>
                    </div>
                  </div>
                  <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                    <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                      <div className="symbol symbol-50px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-basket-ok fs-2qx text-info">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>
                      <div className="me-2">
                        <a
                          href="#"
                          className="text-gray-800 text-hover-primary fs-6 fw-bold"
                        >
                          Pembeli
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-900 fw-bolder fs-2x me-3">
                        {data.buyer}
                      </span>

                      <span className="badge badge-lg badge-light-info align-self-center px-2">
                        {calculatePercentage(data.buyer)}%
                      </span>
                    </div>
                  </div>
                  <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                    <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                      <div className="symbol symbol-50px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-shop fs-2qx text-success">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>
                      <div className="me-2">
                        <a
                          href="#"
                          className="text-gray-800 text-hover-primary fs-6 fw-bold"
                        >
                          Penjual
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-900 fw-bolder fs-2x me-3">
                        {data.seller}
                      </span>

                      <span className="badge badge-lg badge-light-success align-self-center px-2">
                        {calculatePercentage(data.seller)}%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center flex-column">
                  <div
                    ref={chartRef}
                    id="kt_charts_widget_3_chart"
                    style={{ height: "250px" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PieCharts };

function getChartOptions(height: number, data: DashboardWidget): ApexOptions {
  const infoColor = getCSSVariableValue("--bs-info");
  const successColor = getCSSVariableValue("--bs-success");
  const primaryColor = getCSSVariableValue("--bs-primary");
  const dangerColor = getCSSVariableValue("--bs-danger");

  return {
    series: [data.buyer, data.seller, data.expedition],
    chart: {
      fontFamily: "inherit",
      type: "donut",
      width: 250,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            value: {
              fontSize: "10px",
            },
          },
        },
      },
    },
    colors: [infoColor, successColor, primaryColor, dangerColor],
    stroke: {
      width: 0,
    },
    labels: ["Buyer", "Seller", "Expedition"],
    legend: {
      show: false,
    },
    fill: {
      type: "false",
    },
  };
}
