import React, { ChangeEvent, useEffect, useState } from "react";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { EditVariantForm } from "./EditVariant";
import { Image } from "antd";
import { AlertMessage } from "../../global/AlertMessage";

type Props = {
  id: number;
};

interface ProductDetail {
  id: number;
  photos: {
    id: number;
    url: string;
  }[];
  type: string;
  categories: {
    id: number;
    parent_id: number;
    level: string;
    type: string;
    name: string;
  }[];
  name: string;
  merk: string;
  condition: string;
  description: string;
  video_url: string;
  min: number;
  price: number;
  stock: number;
  width: number;
  length: number;
  height: number;
  volume: number;
  weight: string;
  is_have_variant: boolean;
  variant_count: number;
  is_multiple_price: boolean;
  prices: {
    qty: number;
    price: number;
  }[];
  is_free_shipping: boolean;
  is_pre_order: boolean;
}

type Category = {
  id: number;
  parent_id: number;
  level: string;
  type: string;
  name: string;
};

type Level = "main" | "sub" | "sub-sub";

const EditProduct: React.FC<Props> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [zeroData, setZeroData] = useState("");
  const [data, setData] = useState<ProductDetail>({
    id: 0,
    photos: [],
    type: "",
    categories: [],
    name: "",
    merk: "",
    condition: "",
    description: "",
    video_url: "",
    min: 0,
    price: 0,
    stock: 0,
    width: 0,
    length: 0,
    height: 0,
    volume: 0,
    weight: "",
    is_have_variant: false,
    variant_count: 0,
    is_multiple_price: false,
    prices: [],
    is_free_shipping: false,
    is_pre_order: false,
  });
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (currentUser?.token) {
      getProductDetail(id);
    }
  }, [currentUser]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const getProductDetail = async (id: number) => {
    setLoading(true);

    const res = await API.GetProductDetail(token, id);
    if (res.status === 200) {
      setData(res.data.data);
      setPrices(res.data.data.prices);
      setPhotos(res.data.data.photos);
    } else {
      setZeroData("Product not found!");
    }

    setLoading(false);
  };

  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [subSubCategories, setSubSubCategories] = useState<Category[]>([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    number | null
  >(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(
    null
  );
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<
    number | null
  >(null);

  const [meta, setMetaData] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const fetchCategories = async (
    level: Level,
    parentId = 0,
    pageNum = 1,
    isAppending = false
  ) => {
    const res = await API.GetCategory(
      currentUser?.token,
      "",
      level,
      parentId,
      pageNum,
      data.type,
      100000
    );
    if (res.status === 200) {
      const fetchedData = res.data.data.map((item: any) => ({
        id: parseInt(item.id),
        name: item.name,
      }));

      switch (level) {
        case "main":
          setMainCategories((prevCategories) =>
            isAppending ? [...prevCategories, ...fetchedData] : fetchedData
          );
          break;
        case "sub":
          setSubCategories((prevCategories) =>
            isAppending ? [...prevCategories, ...fetchedData] : fetchedData
          );
          break;
        case "sub-sub":
          setSubSubCategories((prevCategories) =>
            isAppending ? [...prevCategories, ...fetchedData] : fetchedData
          );
          break;
        default:
          break;
      }

      setMetaData(res.meta);

      if (pageNum < res.meta.total_pages) {
        fetchCategories(level, parentId, pageNum + 1, true);
      }
    } else {
      console.error(`Error fetching ${level} categories: No data found`);
    }
  };

  useEffect(() => {
    if (data.type) {
      fetchCategories("main", 0, 1);
    }
  }, [currentUser, data.type]);

  useEffect(() => {
    if (data.categories) {
      const mainCategory = data.categories.find(
        (category) => category.level === "main"
      );
      const subCategory = data.categories.find(
        (category) => category.level === "sub"
      );
      const subSubCategory = data.categories.find(
        (category) => category.level === "sub-sub"
      );

      if (mainCategory) {
        setSelectedMainCategory(mainCategory.id);
        fetchCategories("sub", mainCategory.id, 1);
      }

      if (subCategory) {
        setSelectedSubCategory(subCategory.id);
        fetchCategories("sub-sub", subCategory.id, 1);
      }

      if (subSubCategory) {
        setSelectedSubSubCategory(subSubCategory.id);
      }
    }
  }, [data]);

  const handleCategoryChange = (
    level: Level,
    parentId: number,
    setSelectedCategory: React.Dispatch<React.SetStateAction<number | null>>,
    setNextLevelCategories: React.Dispatch<React.SetStateAction<Category[]>>
  ) => {
    setSelectedCategory(parentId);
    setNextLevelCategories([]);
    if (parentId) {
      fetchCategories(level, parentId, 1);
    }
  };

  const handleUpdateProduct = async () => {
    setLoading(true);

    const formDataAPI = new FormData();

    photos.forEach((photo, index) => {
      if (photo.file) {
        formDataAPI.append("photo[]", photo.file);
      }
    });

    const categories = [
      { name: "mainCategory", value: selectedMainCategory },
      { name: "subCategory", value: selectedSubCategory },
      { name: "subSubCategory", value: selectedSubSubCategory },
    ];

    categories.forEach((category) => {
      if (category.value) {
        formDataAPI.append("category[]", category.value.toString());
      }
    });

    formDataAPI.append("type", data.type);
    formDataAPI.append("name", data.name);
    formDataAPI.append("merk", data.merk);
    formDataAPI.append("condition", data.condition);
    formDataAPI.append("description", data.description);
    formDataAPI.append("video_url", data.video_url);

    if (data.min !== null) {
      formDataAPI.append("min", data.min.toString());
    }

    if (data.price !== null) {
      formDataAPI.append("price", data.price.toString());
    }

    if (data.stock !== null) {
      formDataAPI.append("stock", data.stock.toString());
    }
    if (data.width !== null) {
      formDataAPI.append("width", data.width.toString());
    }
    if (data.length !== null) {
      formDataAPI.append("length", data.length.toString());
    }
    if (data.height !== null) {
      formDataAPI.append("height", data.height.toString());
    }
    if (data.volume !== null) {
      formDataAPI.append("volume", data.volume.toString());
    }

    formDataAPI.append("weight", data.weight);
    formDataAPI.append("is_multiple_price", data.is_multiple_price.toString());
    formDataAPI.append("is_free_shipping", data.is_free_shipping.toString());
    formDataAPI.append("is_pre_order", data.is_pre_order.toString());

    if (data.is_multiple_price) {
      formDataAPI.append("prices", JSON.stringify(prices));
    }

    const res = await API.UpdateProduct(formDataAPI, token, data.id);

    if (res.status === 200) {
      await getProductDetail(data.id);
      setSuccessMessage(res.data.message);
      setShowAlert(true);
    } else {
      setErrorMessage(res.data.message);
      setShowAlertError(true);
    }
    setLoading(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [prices, setPrices] = useState<{ qty: number; price: number }[]>(
    data.prices || []
  );
  const [newPrice, setNewPrice] = useState({ qty: "", price: "" });

  const handleAddOption = () => {
    setPrices([
      ...prices,
      {
        qty: Number(newPrice.qty),
        price: Number(newPrice.price.split(".").join("")),
      },
    ]);
    setNewPrice({ qty: "", price: "" });
  };

  const handleChangeOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: "qty" | "price"
  ) => {
    const newPrices = [...prices];
    newPrices[index][field] = Number(e.target.value);
    setPrices(newPrices);
  };

  const handleDeleteOption = (index: number) => {
    setPrices(prices.filter((_, i) => i !== index));
  };

  const [photos, setPhotos] = useState<
    { id: number; url: string; file?: File }[]
  >(data.photos || []);

  const handleAddPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPhotoFile = event.target.files?.[0];
    if (newPhotoFile) {
      const newPhotoUrl = URL.createObjectURL(newPhotoFile);
      const newPhoto = {
        id: photos.length + 1,
        url: newPhotoUrl,
        file: newPhotoFile,
      };
      setPhotos((prevPhotos) => [...prevPhotos, newPhoto]);
    }
  };

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newPhotoFile = event.target.files?.[0];
    if (newPhotoFile) {
      const newPhotoUrl = URL.createObjectURL(newPhotoFile);
      setPhotos((prevPhotos) =>
        prevPhotos.map((photo, idx) =>
          idx === index
            ? { ...photo, url: newPhotoUrl, file: newPhotoFile }
            : photo
        )
      );
    }
  };

  return (
    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div className="card mb-5 mb-xl-10">
            <form
              id="kt_ecommerce_add_product_form"
              className="form d-flex flex-column flex-lg-row"
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateProduct();
              }}
            >
              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="kt_ecommerce_add_product_general"
                    role="tab-panel"
                  >
                    <div className="card card-flush py-4">
                      <div className="card-header">
                        <div className="card-title">
                          <h2>Edit Produk</h2>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <div className="mb-4 fv-row">
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center mb-3 me-3">
                              <div
                                className="image-input image-input-empty image-input-outline image-input-placeholder"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage: `url('/media/svg/files/blank-image.svg')`,
                                }}
                              >
                                <div className="image-input-wrapper w-125px h-125px" />
                                <label
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Add photo"
                                >
                                  <i className="bi-outline bi-pencil fs-7" />
                                  <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleAddPhoto}
                                  />
                                </label>
                              </div>
                            </div>
                            {photos.map((photo, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center mb-3 me-3 position-relative"
                              >
                                <div
                                  className="image-input image-input-empty image-input-outline image-input-placeholder"
                                  data-kt-image-input="true"
                                  style={{
                                    backgroundImage: `url('${photo.url}')`,
                                  }}
                                >
                                  <Image
                                    className="image-input-wrapper w-125px h-125px"
                                    src={photo.url}
                                    alt="Uploaded photo"
                                    width={125}
                                    height={125}
                                    key={index}
                                  />
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change photo"
                                  >
                                    <i className="bi bi-pencil fs-7"></i>
                                    <input
                                      type="file"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={(event) =>
                                        handlePhotoChange(event, index)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Tipe Produk
                          </label>
                          <select
                            name="type"
                            className="form-select mb-2"
                            onChange={handleChangeSelect}
                            value={data.type}
                          >
                            <option value="">Pilih Tipe Produk</option>
                            <option value="material">Material</option>
                            <option value="non-material">Non-Material</option>
                          </select>
                        </div>

                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Nama Produk
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control mb-2"
                            placeholder="Product name"
                            defaultValue={data.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Merk Produk
                          </label>
                          <input
                            type="text"
                            name="merk"
                            className="form-control mb-2"
                            placeholder="Product brand"
                            defaultValue={data.merk}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4 fv-row">
                          <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                            Pre-Order
                          </label>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                              <input
                                name="is_pre_order"
                                className="form-check-input h-30px w-60px"
                                type="checkbox"
                                checked={data.is_pre_order}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="mb-4 fv-row">
                            <label className="form-label">Kategori Utama</label>
                            <select
                              className="form-select mb-2"
                              data-control="select2"
                              data-placeholder="Pilih opsi"
                              data-allow-clear="true"
                              value={selectedMainCategory || ""}
                              onChange={(e) =>
                                handleCategoryChange(
                                  "sub",
                                  parseInt(e.target.value),
                                  setSelectedMainCategory,
                                  setSubCategories
                                )
                              }
                              onFocus={() => fetchCategories("main")}
                            >
                              <option />
                              {mainCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="mb-4 fv-row">
                            <label className="form-label">Sub Kategori</label>
                            <select
                              className="form-select mb-2"
                              data-control="select2"
                              data-placeholder="Pilih opsi"
                              data-allow-clear="true"
                              value={selectedSubCategory || ""}
                              onChange={(e) =>
                                handleCategoryChange(
                                  "sub-sub",
                                  parseInt(e.target.value),
                                  setSelectedSubCategory,
                                  setSubSubCategories
                                )
                              }
                              onFocus={() =>
                                selectedMainCategory &&
                                fetchCategories("sub", selectedMainCategory)
                              }
                            >
                              <option />
                              {subCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="mb-4 fv-row">
                            <label className="form-label">
                              Sub Sub Kategori
                            </label>
                            <select
                              className="form-select mb-2"
                              data-control="select2"
                              data-placeholder="Pilih opsi"
                              data-allow-clear="true"
                              value={selectedSubSubCategory || ""}
                              onChange={(e) =>
                                setSelectedSubSubCategory(
                                  parseInt(e.target.value)
                                )
                              }
                              onFocus={() =>
                                selectedSubCategory &&
                                fetchCategories("sub-sub", selectedSubCategory)
                              }
                            >
                              <option />
                              {subSubCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="mb-4 fv-row">
                          <label className="form-label mb-5 required">
                            Kondisi Produk
                          </label>
                          <div className="d-flex align-items-center fv-row">
                            <div className="form-check form-check-custom form-check-solid me-8">
                              <input
                                className="form-check-input me-3"
                                name="condition"
                                type="radio"
                                id="kt_ecommerce_add_category_automation_0"
                                checked={data.condition === "new"}
                                onChange={handleChange}
                                value="new"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="kt_ecommerce_add_category_automation_0"
                              >
                                <div className="fw-bold text-gray-800">
                                  Baru
                                </div>
                              </label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input me-3"
                                name="condition"
                                type="radio"
                                id="kt_ecommerce_add_category_automation_1"
                                checked={data.condition === "second"}
                                onChange={handleChange}
                                value="second"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="kt_ecommerce_add_category_automation_1"
                              >
                                <div className="fw-bold text-gray-800">
                                  Bekas
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Deskripsi Produk
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={data.description}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4 fv-row">
                          <label className="form-label">
                            Link Video Produk
                          </label>
                          <input
                            type="text"
                            name="video_url"
                            className="form-control mb-2"
                            placeholder="Product video URL"
                            value={data.video_url}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Minimal Pembelian Produk
                          </label>
                          <input
                            type="text"
                            name="min"
                            className="form-control mb-2"
                            placeholder="Minimum purchase quantity"
                            value={data.min}
                            onChange={handleChange}
                          />
                        </div>
                        <div
                          className="mb-4 fv-row"
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <div style={{ flex: 1 }}>
                            <label className="form-label">Width</label>
                            <input
                              type="number"
                              name="width"
                              className="form-control mb-2"
                              placeholder="Product width"
                              value={data.width}
                              onChange={handleChange}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label className="form-label">Height</label>
                            <input
                              type="number"
                              name="height"
                              className="form-control mb-2"
                              placeholder="Product height"
                              value={data.height}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div
                          className="mb-4 fv-row"
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <div style={{ flex: 1 }}>
                            <label className="form-label">Length</label>
                            <input
                              type="number"
                              name="length"
                              className="form-control mb-2"
                              placeholder="Product width"
                              value={data.length}
                              onChange={handleChange}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label className="form-label">Volume</label>
                            <input
                              type="number"
                              name="volume"
                              className="form-control mb-2"
                              placeholder="Product height"
                              value={data.volume}
                              onChange={handleChange}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label className="form-label">Weight</label>
                            <input
                              type="number"
                              name="weight"
                              className="form-control mb-2"
                              placeholder="Product height"
                              value={data.weight}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Harga Produk
                          </label>
                          <input
                            type="text"
                            name="price"
                            className="form-control mb-2"
                            placeholder="Product price"
                            value={data.price}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-4 fv-row">
                          <label className="required form-label">
                            Stok Produk
                          </label>
                          <input
                            type="text"
                            name="stock"
                            className="form-control mb-2"
                            placeholder="Product stock"
                            value={data.stock}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-4 fv-row">
                          <div className="mb-4 fv-row">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                              Harga Borongan
                            </label>
                            <div className="col-lg-8 d-flex align-items-center">
                              <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                                <input
                                  className="form-check-input h-30px w-60px"
                                  name="is_multiple_price"
                                  type="checkbox"
                                  checked={data.is_multiple_price}
                                  id="kt_modal_add_customer_billing"
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                            </div>
                          </div>
                          {data.is_multiple_price && (
                            <div data-kt-ecommerce-catalog-add-product="auto-options">
                              <div id="kt_ecommerce_add_product_options">
                                <div className="form-group">
                                  <div
                                    data-repeater-list="kt_ecommerce_add_product_options"
                                    className="d-flex flex-column gap-3"
                                  >
                                    {prices.map((price, index) => (
                                      <div
                                        key={index}
                                        data-repeater-item=""
                                        className="form-group d-flex align-items-center gap-3"
                                      >
                                        <div className="d-flex flex-column flex-md-row align-items-md-center gap-3 w-100">
                                          <div className="w-100 w-md-200px">
                                            <input
                                              type="text"
                                              className="form-control mw-100 w-200px"
                                              name="product_option_value"
                                              id={`qty-${index}`}
                                              placeholder={
                                                price.qty === 0
                                                  ? "Masukkan kuantitas"
                                                  : ""
                                              }
                                              value={
                                                price.qty === 0 ? "" : price.qty
                                              }
                                              onChange={(e) =>
                                                handleChangeOption(
                                                  e,
                                                  index,
                                                  "qty"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="w-100 w-md-200px">
                                            <input
                                              type="text"
                                              className="form-control mw-100 w-200px"
                                              name="product_option_value"
                                              id={`price-${index}`}
                                              placeholder={
                                                price.price === 0
                                                  ? "Masukkan harga"
                                                  : ""
                                              }
                                              value={
                                                price.price === 0
                                                  ? ""
                                                  : price.price
                                              }
                                              onChange={(e) =>
                                                handleChangeOption(
                                                  e,
                                                  index,
                                                  "price"
                                                )
                                              }
                                            />
                                          </div>

                                          <div className="align-self-md-center">
                                            <button
                                              type="button"
                                              data-repeater-delete=""
                                              className="btn btn-sm btn-icon btn-light-danger"
                                              onClick={() =>
                                                handleDeleteOption(index)
                                              }
                                            >
                                              <i className="bi-duotone bi-x fs-1">
                                                <span className="path1" />
                                                <span className="path2" />
                                              </i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="form-group mt-5">
                                  <button
                                    type="button"
                                    data-repeater-create=""
                                    className="btn btn-sm btn-light-primary"
                                    onClick={handleAddOption}
                                  >
                                    <i className="ki-duotone ki-plus fs-2" />
                                    Tambah harga borongan
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="mb-4 fv-row">
                          <label className="col-lg-4 col-form-label fw-bold fs-6">
                            Kurir Toko
                          </label>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                              <input
                                className="form-check-input h-30px w-60px"
                                name="is_free_shipping"
                                type="checkbox"
                                checked={data.is_free_shipping}
                                id="kt_modal_add_customer_billing"
                                onChange={handleCheckboxChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end pt-6">
                          <button
                            type="submit"
                            id="kt_ecommerce_add_product_submit"
                            className="btn btn-primary"
                          >
                            <span className="indicator-label">
                              Save Changes
                            </span>
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Tab pane*/}
                </div>
              </div>
            </form>
          </div>

          {data.is_have_variant && data.variant_count !== 0 && (
            <EditVariantForm id={id} />
          )}
        </div>
      </div>
    </div>
  );
};

export { EditProduct };
