import { FC, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import Pusher from "pusher-js";
import { Image } from "antd";

type Props = {
  isDrawer?: boolean;
  name: string;
  photo: string;
  messageId: number | null;
};

type Product = {
  id: number;
  photo: string;
};

interface ChatMessage {
  id: number;
  user_id: number;
  product: {
    id: number;
    photo: string;
    name: string;
    variant: null | string;
    price: number;
  } | null;
  transaction: {
    id: number;
    code: string;
    products: Product[];
    grand_total: number;
    status: string;
  } | null;
  photo: null | string;
  message: string;
  is_you: boolean;
  is_read: boolean;
  date: string;
}

const ChatInner: FC<Props> = ({ isDrawer = false, name, photo, messageId }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [reachedTop, setReachedTop] = useState(false);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showButtons, setShowButtons] = useState(false);
  const [zeroData, setZeroData] = useState("");

  const handleMouseEnter = () => setShowButtons(true);
  const handleMouseLeave = () => setShowButtons(false);

  const pusher = new Pusher("b1850c2dae770ae81718", { cluster: "ap1" });
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);

  useEffect(() => {
    const channel = pusher.subscribe(`conversation.${messageId}`);

    channel.bind(`conversation.${messageId}`, (data: ChatMessage) => {
      setMessages((prevMessages) => {
        if (data.user_id !== currentUserId) {
          return [...prevMessages, { ...data }];
        }
        return prevMessages;
      });
    });

    return () => {
      pusher.unsubscribe(`conversation.${messageId}`);
    };
  }, [messageId, currentUserId]);

  useEffect(() => {
    if (messageId !== null) {
      resetChat();
      getDetailChat();
    }
  }, [messageId]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        if (containerRef.current.scrollTop === 0) {
          setReachedTop(true);
        } else {
          setReachedTop(false);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [containerRef]);

  useEffect(() => {
    if (reachedTop && meta.page < meta.total_pages && !loading) {
      getDetailChat(meta.page + 1);
    }
  }, [reachedTop]);

  useEffect(() => {
    if (!reachedTop && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  const resetChat = () => {
    setMessages([]);
    setMeta({
      page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    });
  };

  const getDetailChat = async (page = 1) => {
    setLoading(true);
    try {
      const res = await API.GetDetailChat(token, messageId, page, 999999);
      if (res.status === 200 && res?.data.data.length > 0) {
        setMessages((prevMessages) => [
          ...res.data.data.reverse(),
          ...prevMessages,
        ]);
        const userId = res.data.data.find(
          (message: ChatMessage) => message.is_you
        )?.user_id;
        if (userId) {
          setCurrentUserId(userId);
        }
        setMeta(res.data.meta);
      } else {
        setZeroData(res.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const [messageInput, setMessageInput] = useState<string>("");
  const handleMessageInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessageInput(e.target.value);
  };

  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const MAX_FILE_SIZE_MB = 2048;

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size / (1024 * 1024) <= MAX_FILE_SIZE_MB) {
        setSelectedFile(file);
        setPreviewPhoto(URL.createObjectURL(file));
      }
    }
  };

  const handleDeleteImage = () => {
    setPreviewPhoto(null);
    setSelectedFile(null);
  };

  const handleSendChat = async (messageInput: string, file: File | null) => {
    setLoading(true);
    if (!token || !messageId || !currentUserId) return;

    const formData = new FormData();
    formData.append("role", "admin");
    formData.append("message", messageInput);
    if (file) formData.append("photo", file);

    const resSendChat = await API.SendChat(formData, token, messageId);

    if (resSendChat.status === 200) {
      setMessageInput("");
      setPreviewPhoto("");
    } else {
      console.error("Error sending chat:", resSendChat);
    }
    setLoading(false);
  };

  const formatDate = (date: Date) => {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  };

  const handleSendButtonClick = () => {
    if (!token || !messageId || !currentUserId) return;

    if (messageInput.trim() !== "") {
      handleSendChat(messageInput, selectedFile);
      setMessageInput("");

      const newMessage: ChatMessage = {
        id: Date.now(),
        user_id: currentUserId,
        product: null,
        transaction: null,
        photo: selectedFile ? URL.createObjectURL(selectedFile) : null,
        message: messageInput,
        is_you: true,
        is_read: false,
        date: formatDate(new Date()),
      };

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        return updatedMessages;
      });
    }
  };

  const groupedMessages: { [key: string]: ChatMessage[] } = {};
  messages.forEach((message) => {
    const date = new Date(message.date).toLocaleDateString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  const formatToRupiah = (price: number) => {
    const formatted = `Rp ${Number(price).toLocaleString("id-ID")}`;
    return formatted;
  };

  return (
    <>
      <div
        className="card-header position-sticky top-0 bg-white z-index-1"
        id="kt_chat_messenger_header"
      >
        <div className="card-title">
          <div className="symbol-group symbol-hover">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-45px symbol-circle">
                <img src={photo} alt={name} />
              </div>
              <div className="ms-5">
                <a
                  href="#"
                  className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                >
                  {name}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx("card-body position-relative", {
          "h-300px h-lg-auto": !isDrawer,
        })}
        id={
          isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"
        }
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <div
          className="scroll-y me-n5 h-500px"
          data-kt-element="messages"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
          data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body"
          data-kt-scroll-offset="5px"
          ref={containerRef}
        >
          {messages.map((message) => (
            <div
              key={message.id}
              className={clsx("d-flex mb-10", {
                "justify-content-start": !message.is_you,
                "justify-content-end": message.is_you,
              })}
            >
              <div className="d-flex flex-column align-items">
                <div
                  className={clsx(
                    "p-6 rounded",
                    {
                      "bg-light-info": !message.is_you,
                      "bg-light-primary": message.is_you,
                    },
                    "text-dark fw-bold mw-lg-400px",
                    {
                      "text-start": !message.is_you,
                      "text-end": message.is_you,
                    }
                  )}
                  data-kt-element="message-text"
                >
                  {message.photo && (
                    <div
                      className="position-relative mb-4"
                      style={{ width: "125px", height: "125px" }}
                    >
                      <Image
                        className="w-100 h-100 object-fit-cover rounded"
                        src={message.photo}
                        alt="Uploaded photo"
                        width={125}
                        height={125}
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                  )}
                  <div className="me-3">
                    {message.product && (
                      <div className="card mb-3 shadow-sm border-light rounded">
                        <div className="card-body p-2">
                          <div className="d-flex align-items-center justify-content-start">
                            <div className="me-3">
                              <Image
                                src={message.product.photo}
                                alt={message.product.name}
                                className="rounded object-fit-cover"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                            <div>
                              <h5 className="mb-1 text-dark">
                                {message.product.name}
                              </h5>
                              {message.product.variant && (
                                <p className="text-muted mb-1">
                                  {message.product.variant}
                                </p>
                              )}
                              <p className="card-text text-dark mb-1">
                                {formatToRupiah(message.product.price)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {message.transaction && (
                      <div className="card mb-3 shadow-sm border-light rounded">
                        <div className="card-body p-2">
                          <div className="d-flex align-items-center justify-content-start">
                            {message.transaction.products.length > 0 && (
                              <div className="me-3">
                                {message.transaction.products.map((product) => (
                                  <div
                                    key={product.id}
                                    className="d-flex align-items-center mb-2"
                                  >
                                    <Image
                                      src={product.photo}
                                      alt={`Product ${product.id}`}
                                      className="rounded object-fit-cover"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                            <div>
                              <h6 className="card-subtitle mb-2 text-muted">
                                {message.transaction.code}
                              </h6>
                              <p className="card-text text-dark mb-1">
                                {formatToRupiah(
                                  message.transaction.grand_total
                                )}
                              </p>
                              <p className="card-text text-muted mb-0">
                                {message.transaction.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message.message,
                    }}
                  ></div>
                  <span
                    className={clsx("text-muted fs-7 mt-3", {
                      "text-end": message.is_you,
                    })}
                  >
                    {message.date.split(" ")[3]}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="card-footer pt-4"
        id={
          isDrawer
            ? "kt_drawer_chat_messenger_footer"
            : "kt_chat_messenger_footer"
        }
      >
        <div className="d-flex align-items-center">
          {previewPhoto && (
            <div
              className="position-relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Image
                src={previewPhoto}
                alt="Preview"
                className="img-thumbnail"
                style={{ maxWidth: "100px" }}
              />
              {showButtons && (
                <div className="position-absolute top-0 start-0 d-flex flex-column gap-1">
                  <button className="btn btn-sm btn-icon btn-light-primary">
                    <i className="bi bi-pencil"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-icon btn-light-danger"
                    onClick={handleDeleteImage}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              )}
            </div>
          )}
          <textarea
            className="form-control form-control-flush mb-3 me-2"
            value={messageInput}
            onChange={handleMessageInputChange}
          ></textarea>
        </div>
        <div className="d-flex flex-stack">
          <div className="d-flex align-items-center me-2">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              className="form-control form-control-flush mb-3"
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <button
              className="btn btn-sm btn-icon btn-active-light-primary me-1"
              type="button"
              data-bs-toggle="tooltip"
              title="send image"
              onClick={() => fileInputRef.current?.click()}
            >
              <i className="bi bi-paperclip fs-3"></i>
            </button>
          </div>
          <button
            className="btn btn-primary"
            type="button"
            data-kt-element="send"
            onClick={handleSendButtonClick}
          >
            Kirim
          </button>
        </div>
      </div>
    </>
  );
};

export { ChatInner };
