import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { FC } from "react";

interface UpdateKategoriModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpdatePayment: (
    formData: {
      bank_type: string;
      bank_code: string;
      name: string;
      icon: File | null;
      fee: number;
      is_active: boolean;
    },
    id: number
  ) => void;
  id: number | null;
  payment?: {
    bank_type: string;
    bank_code: string;
    name: string;
    icon: string;
    fee: number;
    is_active: boolean;
  } | null;
}

const UpdatePaymentModal: FC<UpdateKategoriModalProps> = ({
  isOpen,
  onClose,
  handleUpdatePayment,
  id,
  payment,
}) => {
  const [formData, setFormData] = useState({
    bank_type: payment?.bank_type || "",
    bank_code: payment?.bank_code || "",
    name: payment?.name || "",
    icon: payment?.icon ? new File([], payment.icon) : null,
    iconURL: payment?.icon || "",
    fee: payment?.fee || 0,
    is_active: payment?.is_active || false,
  });

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setFormData({
      bank_type: payment?.bank_type || "",
      bank_code: payment?.bank_code || "",
      name: payment?.name || "",
      icon: payment?.icon ? new File([], payment.icon) : null,
      iconURL: payment?.icon || "",
      fee: payment?.fee || 0,
      is_active: payment?.is_active || false,
    });

    if (payment?.icon) {
      setFileList([
        {
          uid: "-1",
          name: "icon",
          status: "done",
          url: payment.icon,
        },
      ]);
    }
  }, [payment]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUploadChange = ({
    file,
    fileList,
  }: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    if (file.status === "done" || file.status === "uploading") {
      setFileList(fileList);
      if (file.originFileObj) {
        setFormData({
          ...formData,
          icon: file.originFileObj as RcFile,
        });
      }
    } else if (file.status === "removed") {
      setFileList([]);
      setFormData({
        ...formData,
        icon: null,
      });
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleClose = () => {
    setFormData({
      bank_type: "",
      bank_code: "",
      name: "",
      icon: null,
      iconURL: "",
      fee: 0,
      is_active: false,
    });
    setFileList([]);
    onClose();
  };

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleClick = async () => {
    if (!isButtonClicked && id !== null) {
      setIsButtonClicked(true);
      await handleUpdatePayment(formData, id);
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className="modal fade show d-block"
            id="kt_modal_add_user"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="fw-bolder">Edit Pembayaran</h2>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm btn-active-icon-primary"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <form id="kt_modal_add_user_form" className="form" noValidate>
                    <div className="fv-row mb-7">
                      <label className="d-block fw-bold fs-6 mb-2 required">
                        Icon
                      </label>
                      <Upload
                        name="icon"
                        listType="picture-card"
                        fileList={fileList}
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => onSuccess && onSuccess("ok"), 0);
                        }}
                        onChange={handleUploadChange}
                        onRemove={() => setFileList([])}
                        accept="image/*"
                      >
                        {fileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        )}
                      </Upload>
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">Nama</label>
                      <input
                        placeholder="Masukkan URL"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Tipe Bank
                      </label>
                      <select
                        name="bank_type"
                        className={clsx(
                          "form-select form-select-muted mb-3 mb-lg-0"
                        )}
                        value={formData.bank_type}
                        disabled
                      >
                        <option value="" disabled>
                          Pilih Tipe Bank
                        </option>
                        <option value="baba_balance">Baba Balance</option>
                        <option value="bank_account">Bank Account</option>
                        <option value="virtual_account">Virtual Account</option>
                        <option value="wallet_account">Wallet Account</option>
                      </select>
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Kode Bank
                      </label>
                      <input
                        placeholder="Masukkan URL"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="text"
                        name="bank_code"
                        value={formData.bank_code}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Biaya Penanganan
                      </label>
                      <input
                        placeholder="Masukkan URL"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="numeric"
                        name="fee"
                        value={formData.fee}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleClick}
                    disabled={isButtonClicked}
                  >
                    {isButtonClicked ? "Menyimpan..." : "Simpan"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export { UpdatePaymentModal };
