import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import { useLocation } from "react-router-dom";
import { Skeleton } from "antd";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";

const profileDetailsSchema = {
  name: "Nama Toko is required",
  phone: "Nomor Telepon is required",
  province: "Provinsi is required",
  city: "Kabupaten/Kota is required",
  district: "Kecamatan is required",
  subdistrict: "Kelurahan/Desa is required",
  address_detail: "Alamat Lengkap is required",
  zip_code: "Kode Pos is required",
  description: "Deskripsi Toko is required",
};

interface ShopData {
  id: number;
  name: string;
  photo: string;
  phone: string;
  owner: string;
  address: string;
  province: { id: number; name: string };
  city: { id: number; name: string };
  district: { id: number; name: string };
  subdistrict: { id: number; name: string };
  zip_code: string;
  address_detail: string;
  lat: string;
  lng: string;
  description: string;
  is_both: boolean;
  is_free_shipping: boolean;
  is_active: boolean;
  is_open: boolean;
  is_verified: boolean;
}

const UpdateShop: React.FC = () => {
  const location = useLocation();
  const state = location.state as ShopData;

  const [loading, setLoading] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [dataProv, setDataProv] = useState<any[]>([]);
  const [dataKab, setDataKab] = useState<any[]>([]);
  const [dataKec, setDataKec] = useState<any[]>([]);
  const [dataKel, setDataKel] = useState<any[]>([]);
  const [datazipCode, setDataZipcode] = useState<any[]>([]);

  const [latLng, setLatLng] = useState<{ lat: string; lng: string }>({
    lat: "",
    lng: "",
  });

  const [province, setProvince] = useState<{ id: number; name: string }>({
    id: 0,
    name: "",
  });
  const [city, setCity] = useState<{ id: number; name: string }>({
    id: 0,
    name: "",
  });
  const [district, setDistrict] = useState<{ id: number; name: string }>({
    id: 0,
    name: "",
  });
  const [subdistrict, setSubdistrict] = useState<{ id: number; name: string }>({
    id: 0,
    name: "",
  });
  const [zip_code, setZipCode] = useState<number>(0);

  const [formData, setFormData] = useState({
    name: state.name,
    phone: state.phone,
    province: state.province.id,
    city: state.city.id,
    district: state.district.id,
    subdistrict: state.subdistrict.id,
    zip_code: state.zip_code,
    address_detail: state.address_detail,
    description: state.description,
    is_both: state.is_both,
    is_free_shipping: state.is_free_shipping,
    is_open: state.is_open,
    is_verified: state.is_verified,
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    province: "",
    city: "",
    district: "",
    subdistrict: "",
    zip_code: "",
    address_detail: "",
    description: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const resProv = await API.GetRegion(currentUser?.token, "province");
      if (resProv.status === 200) {
        const provData = resProv.data.data.map((item: any) => ({
          id: parseInt(item.id),
          name: item.value,
        }));
        setDataProv(provData);
        setProvince({ id: state.province.id, name: state.province.name });
        handleRegion("city", state.province.id, 0, 0, 0);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const handleRegion = async (
    label: string,
    provid: number,
    kabid: number,
    kecid: number,
    kelid: number
  ) => {
    let param = "";
    try {
      switch (label) {
        case "city":
          param = `city?province=${provid}`;
          const resCity = await API.GetRegion(currentUser?.token, param);
          if (resCity.status === 200) {
            const cityData = resCity.data.data.map((item: any) => ({
              id: parseInt(item.id),
              name: item.value,
            }));
            setDataKab(cityData);
            setCity({ id: state.city.id, name: state.city.name });
            handleRegion("district", provid, state.city.id, 0, 0);
          }
          break;
        case "district":
          param = `district?province=${provid}&city=${kabid}`;
          const resDistrict = await API.GetRegion(currentUser?.token, param);
          if (resDistrict.status === 200) {
            const districtData = resDistrict.data.data.map((item: any) => ({
              id: parseInt(item.id),
              name: item.value,
            }));
            setDataKec(districtData);
            setDistrict({ id: state.district.id, name: state.district.name });
            handleRegion("sub-district", provid, kabid, state.district.id, 0);
          }
          break;
        case "sub-district":
          param = `sub-district?province=${provid}&city=${kabid}&district=${kecid}`;
          const resSubDistrict = await API.GetRegion(currentUser?.token, param);
          if (resSubDistrict.status === 200) {
            const subdistrictData = resSubDistrict.data.data.map(
              (item: any) => ({
                id: parseInt(item.id),
                name: item.value,
              })
            );
            setDataKel(subdistrictData);
            setSubdistrict({
              id: state.subdistrict.id,
              name: state.subdistrict.name,
            });
            handleRegion(
              "zip-code",
              provid,
              kabid,
              kecid,
              state.subdistrict.id
            );
          }
          break;
        case "zip-code":
          param = `zip-code?province=${provid}&city=${kabid}&district=${kecid}&sub-district=${kelid}`;
          const resZipCode = await API.GetRegion(currentUser?.token, param);
          if (resZipCode.status === 200) {
            const zipCodeData = resZipCode.data.data.map((item: any) => ({
              id: parseInt(item.id),
              name: item.value,
              lat: item.lat,
              lng: item.lng,
            }));
            setDataZipcode(zipCodeData);

            setZipCode(zipCodeData[0].name);
            setLatLng({ lat: zipCodeData[0].lat, lng: zipCodeData[0].lng });
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching ${label}:`, error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    const formDataAPI = new FormData();
    if (photoFile) formDataAPI.append("photo", photoFile);
    formDataAPI.append("name", formData.name);
    formDataAPI.append("phone", formData.phone);
    formDataAPI.append("province_id", formData.province.toString());
    formDataAPI.append("city_id", formData.city.toString());
    formDataAPI.append("district_id", formData.district.toString());
    formDataAPI.append("subdistrict_id", formData.subdistrict.toString());
    formDataAPI.append("zip_code", zip_code.toString());
    formDataAPI.append("lat", latLng.lat);
    formDataAPI.append("lng", latLng.lng);
    formDataAPI.append("address_detail", formData.address_detail);
    if (formData.description) {
      formDataAPI.append("description", formData.description);
    }

    formDataAPI.append("is_both", formData.is_both.toString());
    formDataAPI.append(
      "is_free_shipping",
      formData.is_free_shipping.toString()
    );
    formDataAPI.append("is_open", formData.is_open.toString());
    formDataAPI.append("is_verified", formData.is_verified.toString());

    const resUpdateShop = await API.UpdateShop(formDataAPI, token, state.id);

    if (resUpdateShop.status === 200) {
      setShowAlert(true);
      setSuccessMessage(resUpdateShop.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateShop.message);
    }

    setLoading(false);
  };

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size / (1024 * 1024) > 2) {
        alert("Ukuran file harus kurang dari 2 MB.");
      } else {
        setPhotoFile(file);
        setPreviewPhoto(URL.createObjectURL(file));
      }
    }
  };

  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProvinceId = parseInt(e.target.value);

    setFormData({
      ...formData,
      province: selectedProvinceId,
      city: 0,
      district: 0,
      subdistrict: 0,
      zip_code: "",
    });
    handleRegion("city", selectedProvinceId, 0, 0, 0);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCityId = parseInt(e.target.value);

    setFormData({
      ...formData,
      city: selectedCityId,
      district: 0,
      subdistrict: 0,
      zip_code: "",
    });
    handleRegion("district", formData.province, selectedCityId, 0, 0);
  };

  const handleDistrictChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDistrictId = parseInt(e.target.value);

    setFormData({
      ...formData,
      district: selectedDistrictId,
      subdistrict: 0,
      zip_code: "",
    });
    handleRegion(
      "sub-district",
      formData.province,
      formData.city,
      selectedDistrictId,
      0
    );
  };

  const handleSubdistrictChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedsubDistrictId = parseInt(e.target.value);

    setFormData({
      ...formData,
      subdistrict: selectedsubDistrictId,
      zip_code: "",
    });
    handleRegion(
      "zip-code",
      formData.province,
      formData.city,
      formData.district,
      selectedsubDistrictId
    );
  };

  const handleChangeProductType = (isBothSelected: boolean) => {
    setFormData({
      ...formData,
      is_both: isBothSelected,
    });
  };

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Edit Toko</h3>
          </div>
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form onSubmit={handleSubmit}>
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bolder fs-6">
                  Foto Profil
                </label>
                <div className="col-lg-8">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                  >
                    {loading ? (
                      <Skeleton.Avatar size={125} shape="circle" active />
                    ) : (
                      <div
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          borderRadius: "100%",
                          backgroundImage: `url(${
                            previewPhoto || (state.photo ? state.photo : "")
                          })`,
                        }}
                      ></div>
                    )}
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>
                      <input
                        type="file"
                        name="icon"
                        accept=".png, .jpg, .jpeg"
                        onChange={handlePhotoChange}
                      />
                    </label>
                  </div>
                  <div className="form-text">
                    Tipe file yang diizinkan: png, jpg, jpeg. Ukuran maksimum
                    file: 2 MB.
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Nama Toko
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Nama Toko"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Nomor Telepon
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    name="phone"
                    type="number"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Nomor Telepon"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Provinsi</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    name="province"
                    value={formData.province}
                    className="form-select form-select-solid form-select-lg fw-bold"
                    onChange={handleProvinceChange}
                  >
                    <option value="">Pilih Provinsi</option>
                    {dataProv.map((prov) => (
                      <option key={prov.id} value={prov.id}>
                        {prov.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Kabupaten/Kota</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    name="city"
                    className="form-select form-select-solid form-select-lg fw-bold"
                    value={formData.city}
                    onChange={handleCityChange}
                  >
                    <option value="">Pilih Kabupaten/Kota</option>
                    {dataKab.map((kab) => (
                      <option key={kab.id} value={kab.id}>
                        {kab.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Kecamatan</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    name="district"
                    className="form-select form-select-solid form-select-lg fw-bold"
                    value={formData.district}
                    onChange={handleDistrictChange}
                  >
                    <option value="">Pilih Kecamatan</option>
                    {dataKec.map((kec) => (
                      <option key={kec.id} value={kec.id}>
                        {kec.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Kelurahan/Desa</span>
                </label>
                <div className="col-lg-8 fv-row">
                  {loading ? (
                    <Skeleton.Input
                      style={{ width: "100%" }}
                      size="large"
                      active
                    />
                  ) : (
                    <select
                      name="subdistrict"
                      className="form-select form-select-solid form-select-lg fw-bold"
                      value={formData.subdistrict}
                      onChange={handleSubdistrictChange}
                    >
                      <option value="">Pilih Kelurahan/Desa</option>
                      {dataKel.map((kel) => (
                        <option key={kel.id} value={kel.id}>
                          {kel.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Kode Pos</span>
                </label>
                <div className="col-lg-8 fv-row">
                  {loading ? (
                    <Skeleton.Input
                      style={{ width: "100%" }}
                      size="large"
                      active
                    />
                  ) : (
                    <input
                      className="form-control form-control-lg form-control-solid"
                      disabled
                      value={datazipCode.length > 0 ? datazipCode[0].name : ""}
                    />
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Alamat Lengkap
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="address_detail"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Alamat Lengkap"
                    value={formData.address_detail}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Deskripsi Toko
                </label>
                <div className="col-lg-8 fv-row">
                  <textarea
                    name="description"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Deskripsi Toko"
                    value={formData.description}
                    onChange={handleInputChange}
                    style={{
                      minHeight: "100px",
                      height: "auto",
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  />
                </div>
              </div>

              <div className="row mb-0">
                <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                  Tipe Produk
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="form-check form-check-custom form-check-solid me-6">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_both"
                      id="flexRadioDefault2"
                      checked={formData.is_both}
                      onChange={() => handleChangeProductType(true)}
                    />
                    <label className="form-check-label">
                      Material dan Non-Material
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_both"
                      id="flexRadioDefault1"
                      checked={!formData.is_both}
                      onChange={() => handleChangeProductType(false)}
                    />
                    <label className="form-check-label">Non-Material</label>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                  Kurir Toko
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                    <input
                      name="is_free_shipping"
                      className="form-check-input h-30px w-60px"
                      type="checkbox"
                      checked={formData.is_free_shipping}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_free_shipping: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                  Fitur Toko Libur
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                    <input
                      name="is_open"
                      className="form-check-input h-30px w-60px"
                      type="checkbox"
                      checked={!formData.is_open}
                      onChange={(e) =>
                        setFormData({ ...formData, is_open: !formData.is_open })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                  Terverifikasi
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                    <input
                      name="is_verified"
                      className="form-check-input h-30px w-60px"
                      type="checkbox"
                      checked={formData.is_verified}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_verified: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {!loading && "Simpan Perubahan"}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export { UpdateShop };
